// src/app/store/search/search.state.ts
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetSearchQuery, ClearSearchQuery } from './search.actions';

export interface SearchStateModel {
  [key: string]: string;
}

@State<SearchStateModel>({
  name: 'search',
  defaults: {},
})
export class SearchState {
  @Selector()
  static getSearchQuery(searchType: string) {
    return (state: SearchStateModel): string => {
      return state[searchType] || '';
    };
  }

  @Action(SetSearchQuery)
  setSearchQuery(ctx: StateContext<SearchStateModel>, action: SetSearchQuery) {
    const state = ctx.getState();
    ctx.setState({ ...state, [action.searchType]: action.query });
  }

  @Action(ClearSearchQuery)
  clearSearchQuery(ctx: StateContext<SearchStateModel>, action: ClearSearchQuery) {
    const state = ctx.getState();
    const { [action.searchType]: removed, ...newState } = state;
    ctx.setState(newState);
  }
}
